<template>
  <div class="container-auctionhouse">
    <div class="auctionhouse-text">
      精品拍卖会
    </div>
    <div class="auctionhouse-main">
      <div v-for="(item,index) in boutiqueAuctionList" :key="index" class="hidden">
        <div class="mask-img"></div>
        <a :href="item.url" target="_blank" class="mask-img-two"></a>

        <img class="auction-img-masking" src="../../assets/images/juxing.png" alt="">

        <div class="auction-img-text">
          <div class="text-left-right">
            <span class="width-auction">{{item.startTime}}</span>
            <span class="line-auction">|</span>
            <span class="width-auction-nowrap">{{item.auctionHouseTitle}}</span>
          </div>
          <a class="text-bottom" :href="item.url" target="_blank">
            {{item.title}}
          </a>
        </div>

        <div class="forBackgroundImage">
          <img :src="item.image" alt="">
        </div>
      </div>
    </div>
    <div class="auctionhouse-main-top">拍卖行</div>
    <div class="auctionhouse-main-all">
      <a-select default-value="请选择国家" @select="handleChange">
        <a-select-option :value="item.id" v-for="(item,index) in valueAttributeCount" :key="index">
          {{item.name}}
        </a-select-option>
      </a-select>
      <div class="allcounty">
        <a href="javascript:;" @click="searchLineAll()">全部</a>
        <a href="javascript:;" @click="searchLine('#')">#</a>
        <a href="javascript:;" @click="searchLine(item)" v-for="(item,index) in searchData" :key="index">{{item}}</a>
      </div>
    </div>
    <div class="auctionhouse-bottom">
      <div v-for="(item,index) in auctionHouseListFor" :key="index">
        <router-link target="_blank" :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.id}}">
          {{item.name}}
        </router-link>
        <!-- <a href="javascript:" @click="auctionhousedetails(item.id)">{{item.name}}</a> -->
      </div>
    </div>
    <div class="pagination">
      <div id="components-pagination-demo-mini">
        <a-pagination size="small" :total="total" @change="onChange" v-model="auctionHouseListDate.page"
          :defaultPageSize='auctionHouseListDate.pageSize' :defaultCurrent='auctionHouseListDate.page' />
      </div>
    </div>
  </div>
</template>
<script>
  import {
    auctionHouseList,
    auctionList,
    attribute
  } from '../../api/index'
  export default {
    name: 'Auctionhouse',
    data() {
      return {
        value: '',
        boutiqueAuctionList: [],
        auctionHouseListDate: {
          title: '',
          location: '',
          page: 1,
          pageSize: 30,
          userid: Number(sessionStorage.getItem('accessId')) || 0
        },
        allCount: {
          id: 10000,
          name: '全部国家'
        },
        auctionHouseListFor: [],
        auctionListData: {
          title: '',
          address: '',
          auctionWayId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          auctionType: 11,
          page: 1,
          pageSize: 99,
          userid: Number(sessionStorage.getItem('accessId')) || 0,
          classifyId: ''

        },
        valueAttributeCount: [],
        total: 0,
        searchData: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
          'U', 'V', 'W', 'X', 'Y', 'Z'
        ]
      }
    },
    destroyed() {
      sessionStorage.removeItem('msgInfo10')
    },
    created() {
      if (sessionStorage.getItem('msgInfo10')) {
        // 如果有就读取缓存里面的数据
        this.auctionHouseListDate.page = Number(sessionStorage.getItem('currentPage10')) || 1
        this.auctionHouseListDate.pageSize = Number(sessionStorage.getItem('pageSize10')) || 30
      } else {
        this.auctionHouseListDate.page = 1
        this.auctionHouseListDate.pageSize = 30
        // 从其他页面第一次进入列表页，清掉缓存里面的数据
        sessionStorage.removeItem('currentPage10')
        sessionStorage.removeItem('pageSize10')
      }
      // sessionStorage.setItem('msgInfo', true)
      this.auctionHouseList()
      this.auctionList()
      this.attribute()
    },
    methods: {
      // 跳转拍卖行详情
      auctionhousedetails(id) {
        sessionStorage.setItem('currentPage10', this.auctionHouseListDate.page)
        sessionStorage.setItem('pageSize10', this.auctionHouseListDate.pageSize)
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      },
      // 点击切换分页
      onChange(page) {
        this.auctionHouseListDate.page = page
        this.auctionHouseList()
      },
      // 国家列表
      async attribute() {
        const res = await attribute({
          id: 0
        })
        if (res.code === 0) {
          this.valueAttributeCount = res.data.list[4].valueAttribute
          this.valueAttributeCount.unshift(this.allCount)
        } else {
          this.$message.error(res.msg)
        }
      },
      // 初始化精品拍卖会数据
      async auctionList() {
        const res = await auctionList(this.auctionListData)
        if (res.code === 0) {
          this.boutiqueAuctionList = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      // 点击国家查询
      handleChange(value) {
        if (value === 10000) {
          this.auctionHouseListDate.location = ''
        } else {
          this.auctionHouseListDate.location = value.toString()
        }
        this.auctionHouseList()
      },
      // 拍卖行列表
      async auctionHouseList() {
        const res = await auctionHouseList(this.auctionHouseListDate)
        if (res.code === 0) {
          this.auctionHouseListFor = res.data.list
          this.total = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转拍卖会详情
      details(url) {
        // this.$router.push({
        //   path: '/auctiondetails',
        //   name: 'Auctiondetails',
        //   query: {
        //     auctiondetailsId: id
        //   }
        // })
        location.href = url
      },
      // 点击全部
      searchLineAll() {
        this.auctionHouseListDate.title = ''
        this.auctionHouseListDate.page = 1
        this.auctionHouseList()
      },
      // 点击字母查询
      searchLine(value) {
        this.auctionHouseListDate.title = value
        this.auctionHouseListDate.page = 1
        this.auctionHouseList()
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-auctionhouse {
    margin: 0 90px;

    /deep/ .ant-select {
      width: 430px;
    }

    /deep/ .ant-select-open .ant-select-selection {
      border-color: #cda156;
    }

    /deep/ .ant-select-selection:hover {
      border-color: #cda156;
    }

    .pagination {
      // margin-bottom: 60px;
      display: flex;
      padding-bottom: 30px;

      #components-pagination-demo-mini {
        flex: 1;
        text-align: center;
        // padding-right: 102px;
      }
    }

    /deep/ .ant-pagination-item {
      font-size: 16px;
    }

    /deep/ .ant-pagination-item-active {
      border: none;
    }

    #components-pagination-demo-mini .ant-pagination:not(:last-child) {
      margin-bottom: 24px;
    }

    /deep/ .ant-pagination-item-active a {
      color: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #cda156;
    }

    /deep/ .ant-pagination-item-active a {
      color: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #cda156;
    }

    .el-select {
      width: 100px;
      height: 50px;
    }

    /deep/ .ant-pagination {
      font-size: 18px;
    }

    /deep/ .ant-collapse-header {
      text-align: left;
      padding: 30px 5px;
      font-size: 24px;
    }

    /deep/ .ant-pagination-item {
      border: 1px solid #ccc !important;
    }

    /deep/ .ant-collapse {
      background-color: #fff;
      border: none;
      position: relative;
    }

    /deep/ .ant-select {
      // width: 80px !important;
    }

    /deep/ .ant-select-selection__placeholder {
      color: rgba(0, 0, 0, 0.65);
    }

    /deep/ .ant-pagination-item-active a {
      color: #fff;
      background: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #000;
    }

    /deep/ .ant-pagination-item {
      border: 1px solid #ccc !important;
      margin: 0px 5px;

      a {
        font-size: 15px;
      }
    }

    /deep/ .ant-pagination-prev {
      border: 1px solid #ccc !important;
      margin-right: 5px;
    }

    /deep/ .ant-pagination-next {
      border: 1px solid #ccc !important;
      margin-left: 5px;
    }

    /deep/ .anticon {
      vertical-align: 0;
    }

    /deep/ .ant-pagination-item-active {
      background: #cda156;
    }

    /deep/ .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: #cda156;
      box-shadow: none;
    }

    /deep/ .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #cda156 !important;
    }

    .auctionhouse-text {
      margin-top: 50px;
      font-size: 36px;
      font-weight: 500;
      color: #333;
      text-align: left;
    }

    .auctionhouse-main {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      width: 1800px;

      .hidden:hover .mask-img {
        opacity: 1;
        cursor: default;
      }

      .hidden {
        width: 556px;
        height: 370px;
        margin-right: 36px;
        position: relative;
        margin-bottom: 30px;
        overflow: hidden;

        .forBackgroundImage {
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 100%;
          // z-index: -1;
          margin: 0;
          opacity: 1;
          height: 100%;
        }

        .mask-img {
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          background: rgba(101, 101, 101, 0.4);
          color: #ffffff;
          opacity: 0;
          margin: 0;
          left: 0;
        }

        .mask-img-two {
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          background: rgba(101, 101, 101, 0.4);
          color: #ffffff;
          opacity: 0;
          margin: 0;
          left: 0;
          z-index: 1;
        }

        img {
          width: 100%;
        }

        .auction-img-masking {
          position: absolute;
          left: 0;
          bottom: 0;
        }

        .auction-img-text {
          width: 495px;
          // height: 55px;
          position: absolute;
          left: 15px;
          bottom: 20px;
          text-align: left;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .text-left-right {
            display: flex;
            font-family: sans-serif, LisztFY-Rg;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            span {
              color: #fff;
              font-size: 18px;
              font-weight: 600;
            }

            .width-auction {
              // padding: 0 10px;
              width: 180px;
            }

            .line-auction {
              padding: 0 10px;
            }

            .width-auction-nowrap {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // padding: 0 10px;
              width: 300px;
            }
          }

          .text-bottom {
            // width: 400px;
            display: block;
            margin-top: 10px;
            font-size: 30px;
            font-weight: 600;
            color: #fff;
            z-index: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .auctionhouse-main-top {
      margin-top: 50px;
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: 500;
      color: #333;
      text-align: left;
    }

    .auctionhouse-main-all {
      height: 84px;
      margin-bottom: 30px;
      border-top: 1px solid #979797;
      border-bottom: 1px solid #979797;
      display: flex;
      align-items: center;

      .allcounty {
        margin-left: 40px;
        font-size: 16px;
        font-weight: 400;

        a {
          color: #333;

          padding-right: 20px;
        }

        a:hover {
          color: #cda156;
        }
      }
    }

    .auctionhouse-bottom {
      display: flex;
      justify-content: space-between;
      margin-bottom: 78px;
      flex-flow: wrap;

      div {
        width: 500px;
        display: flex;
        flex-direction: column;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          padding-bottom: 5px;
          color: #333;
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .auctionhouse-pagination {
      margin-bottom: 60px;
    }
  }
</style>
